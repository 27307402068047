import React from "react"
import { navigate, graphql } from "gatsby"

/* Import Global Hooks*/
import useFirebaseUser from "~hooks/useFirebaseUser"

/* Import Global Component(s) */
import BlockContent from "~components/blockContent/blockContent"
import Footer from "~components/footer/footer"
import Image from "~components/image/image"
import Page from "~components/page/page"
import PageArticle from "~components/page/components/pageArticle/pageArticle"
import PageArticleSection from "~components/page/components/pageArticle/components/pageArticleSection/pageArticleSection"
import Card from "~components/card/card"

/* Import Local Components */
import EventHeader from "./components/eventHeader/eventHeader"

/* Import Local Style(s) */
import "./event.scss"

const Event = ({ location, data: { sanityProgramEvent: programEvent } }) => {
  const firebaseUser = useFirebaseUser()
  const { image, _rawBody, vip, rsvpUrl } = programEvent

  if (!firebaseUser && vip && location.pathname !== `/`) {
    if (typeof window !== `undefined`) {
      navigate("/")
      return null
    }
  }

  return (
    <Page title="Event" location={location} className="template">
      <EventHeader event={programEvent} location={location} />
      <PageArticle title="Event" className="event">
        <PageArticleSection className="event">
          {image && <Image image={image.asset.gatsbyImageData} />}
          <BlockContent blocks={_rawBody} />
          {vip && rsvpUrl && (
            <a href={rsvpUrl} target="_blank" rel="noreferrer">
              <Card
                backgroundColor={"var(--color-lightest-gray)"}
                className="program-event"
              >
                RSVP
              </Card>
            </a>
          )}
        </PageArticleSection>
      </PageArticle>
      <Footer />
    </Page>
  )
}

export default Event

export const query = graphql`
  query ($id: String!) {
    sanityProgramEvent(id: { eq: $id }) {
      ...fieldsOnProgramEventDocument
    }
  }
`
