import { useContext } from "react"

import AuthContext from "~context/auth"

const useFirebaseUser = () => {
  const firebaseUser = useContext(AuthContext)
  // if the user is a VIP
  return firebaseUser
}

export default useFirebaseUser
